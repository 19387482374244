<template>
  <div class="card">
    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="listItems"
              empty-text="Nenhuma transação encontrada no período informado.">
      <el-table-column label="Descrição"
                       prop="name"
                       min-width="290px"
                       sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">
                <img src="/img/icons/logo-pix-icone-64-green.png" alt="Pix" height="16"
                     v-if="row.transactionType == 'PIX_DEPOSIT'"/>
                <img src="/img/icons/logo-pix-icone-64-red.png" alt="Pix" height="16"
                     v-else-if="row.transactionType == 'PIX_PAYMENT'"/>
                <i class="fas fa-exchange-alt text-green"
                   v-else-if="row.transactionType == 'TEV'
                    && row.destination == '@'+usernameDisplay"/>
                <i :class="getTransactionLogo(row.transactionType)" v-else/>
                <span style="font-size: 10px; padding-left: 10px">{{getTransactionName(row.transactionType, row.transactionSubType)}}</span>
              </span>
            </div>
            <base-button @click="printReceipt(row.idTransaction)"
                         v-if="['TED','TEV','PIX_PAYMENT','BANK_SLIP_PAYMENT'].includes(row.transactionType)&&!row.in"
                         class="like btn-link"
                         size="sm"
                         title="Ver Comprovante"
                         icon>
              <i class="fas fa-file-invoice text-white"/>
            </base-button>

            <el-popover
              :title="getPopOverTitle(row.transactionType)"
              placement="bottom"
              trigger="click"
              v-if="!isNullOrEmpty(row.userComment)">
              <span class="text-uppercase text-warning">{{row.userComment}}</span>
              <base-button
                class="like btn-link fas fa-comment-dots text-white"
                size="sm"
                title="Ver Descrição"
                icon
                slot="reference">
              </base-button>
            </el-popover>

          </div>
        </template>
      </el-table-column>
      <el-table-column el-table-column label="Origem/Destino"
                       prop="name"
                       min-width="310px"
                       sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
            <div class="media-body">
              <span style="font-size: 10px"
                    v-if="['BANK_SLIP_PAYMENT','TED','PIX_PAYMENT'].includes(row.transactionType)
                    || row.source == '@'+usernameDisplay">
                {{row.destination}}
              </span>
              <span style="font-size: 10px"
                    v-else-if="['TEV','PIX_DEPOSIT'].includes(row.transactionType)">
                {{row.source}}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Valor"
                       prop="budget"
                       min-width="140px"
                       sortable>
        <template v-slot="{row}">
          <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">
                <i style="font-size: 12px" class="ni ni-fat-add text-green"
                   v-if="row.transactionType == 'TEV'
                    && row.destination == '@'+usernameDisplay"/>
                <i style="font-size: 12px" :class="getTransactionIcon(row.transactionType)" v-else/>
                <span style="font-size: 12px" class="font-weight-600">R$ {{toMoney(row.fundsMovement)}}</span>
              </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Saldo"
                       prop="budget"
                       min-width="140px"
                       sortable>
        <template v-slot="{row}">
          <badge class="badge-dot mr-4" type="">
            <span style="font-size: 12px" class="font-weight-600">R$ {{toMoney(row.accountBalance)}}</span>
          </badge>
        </template>
      </el-table-column>
      <el-table-column label="Data"
                       prop="status"
                       min-width="180px"
                       sortable>
        <template v-slot="{row}">
          <badge class="badge-dot mr-4" type="">
            <span class="status" style="font-size: 11px">{{row.transactionDate}}</span>
          </badge>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {getTransactionName, getTransactionLogo, getTransactionIcon} from '@/util/transation.utils'
  import {toMoney, isNullOrEmpty} from '@/util/core.utils'
  import {callWs, failWs, postWs} from "@/ws.service"

  export default {
    name: 'all-transactions-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        usernameDisplay: ''
      }
    },
    mounted() {
      this.usernameDisplay = localStorage.getItem("usernameDisplay")
    },
    props: {
      itemLimit: {
        type: Number,
        default: 9
      },
      listItems: {
        type: Array
      }
    },
    methods: {
      toMoney,
      getTransactionLogo,
      getTransactionName,
      getTransactionIcon,
      isNullOrEmpty,
      getPopOverTitle(trType) {
        if (trType === 'TED' || trType === 'TEV') {
          return "Identificação da Transação";
        }
        if (trType === 'PIX_PAYMENT') {
          return "Comentário do Pagamento";
        }
        if (trType === 'PIX_DEVOLUTION') {
          return "Comentário da Devolução";
        }
        if (trType === 'PIX_DEPOSIT') {
          return "Comentário da Cobrança";
        }

      },
      printReceipt(id) {
        callWs("/receipt/get-receipt-transactions", true,
          "POST", null, true,
          {idTransaction: id},
          null,
          this.successPrintReceipt,
          this.errorWs)
      },
      errorWs() {
        this.$swal({
          title: 'Erro!',
          text: "Favor tentar novamente mais tarde!",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          },
          icon: 'danger'
        })
      },
      successPrintReceipt(response) {
        this.$router.push({
          name: 'ShowPdf',
          params: {pageToBack: '/pages/transactions', pdfBase64: response.data.pdfBase64}
        })
      }
    }
  };
</script>
