<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">{{this.nickname()}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <router-link to="/"><i class="fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            </ol>
          </nav>
        </div>
      </div>

    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6">

      <!--Tables & Widgets-->
      <div class="row">
        <div class="col-xl-12">
          <div class="border-0 card-header">
            <h3 class="mb-0">Últimas Transações</h3>
          </div>
          <all-transactions-table :item-limit="5" :list-items="this.timelineItems"></all-transactions-table>
        </div>
      </div>
      <!--End Tables & Widgets-->

      <!--Lists-->

    </div>

  </div>
</template>
<script>
  // Components
  import BaseHeader from '@/components/BaseHeader';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // Tables
  import AllTransactionsTable from '@/components/UIComponents/Dzs/AllTransactionsTable';

  import {isNullOrEmpty, toMoney} from "@/util/core.utils"
  import {callWs, failWs} from "@/ws.service";
  import {postWs} from "../../ws.service";

  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      AllTransactionsTable
    },
    data() {
      return {
        name: '',
        successCard: {
          type: 'success',
          icon: 'nc-icon nc-money-coins',
          title: 'Saldo Disponível',
          value: 0,
          footerText: 'Atualizar valores',
          action: this.callHistoryWs,
          footerIcon: 'nc-icon nc-refresh-69'
        },
        blockedCard: {
          type: 'danger',
          icon: 'nc-icon nc-lock-circle-open',
          title: 'Saldo Bloqueado',
          value: 0,
          footerText: 'Nenhuma transação bloqueada',
          action: '',
          footerIcon: 'nc-icon nc-bell-55'
        },
        historyBalanceChart: {
          labels: [],
          series: []
        },
        timelineItems: []
      }
    },
    mounted() {
      this.name = localStorage.getItem("name")
      if (this.name == null || this.name == 'null') {
        this.name = ''
      }

      if (window.location.href.includes('olimpopay.com.br')
        && location.protocol != 'https:') {
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
        return
      }
      this.checkDocs()
      this.callHistoryWs()
      this.getStatusAccount()

    },
    methods: {
      checkDocs() {
        if (localStorage.getItem('docsOk') == 'false') {
          this.$swal({
            title: 'Lembrete',
            text: "Para ter acesso à todas funcionalidades, é necessário verificar a conta, completando o seu cadastro.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'COMPLETAR CADASTRO',
            cancelButtonText: 'agora não'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/pages/user')
            }
          })
        }
      },
      callHistoryWs() {
        callWs("/transaction/transactions-by-period", true,
          "POST", null, true, null,
          {pageNumber: 0, pageSize: 20},
          this.loadDashboard,
          failWs)
      },
      loadDashboard(response) {
        let transactions = response.data.list
        if (transactions.length <= 0) {
          return
        }
        this.timelineItems = transactions
        this.createCards(transactions[0])
        this.createChart(transactions)
      },
      createCards(first) {
        localStorage.setItem('accountBalance', first.accountBalance)
        this.successCard.value = first.accountBalance
        this.blockedCard.value = first.accountBlockedBalance
      },
      createChart(transactions) {
        let labels = []
        let series = []
        let lastBalanceDate = ""
        let lastBalance = 0.00
        for (let transaction in transactions) {
          let position = (parseInt(transaction) + 1)
          let transactionDate = transactions[transactions.length - position].transactionDate.substring(0, 5)
          let accountBalance = transactions[transactions.length - position].accountBalance
          if (transactionDate != lastBalanceDate) {
            labels.push(lastBalanceDate)
            series.push(lastBalance.toFixed(2))
          }
          if (position == transactions.length) {
            labels.push(transactionDate)
            series.push(accountBalance.toFixed(2))
          } else {
            lastBalanceDate = transactionDate
            lastBalance = accountBalance
          }
        }
        this.historyBalanceChart.labels = labels
        this.historyBalanceChart.series = series
      },
      printReceipt(id) {
        postWs("/receipt/get-receipt-transactions",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          failWs)
      },
      successPrintReceipt(response) {
        this.$router.push({
          name: 'ShowPdf',
          params: {pageToBack: '/dashboard', pdfBase64: response.data.pdfBase64}
        })
      },
      getStatusAccount() {
        callWs("/acc/get-status-account", true,
          "POST", null, true, null, null,
          this.loadStatusAccount,
          failWs)
      },
      loadStatusAccount(response) {
        if (response.data == 'PENDING_DOCUMENT') {
          this.successCard.footerText = 'Status: Conta Pendente de Documentação'
          this.successCard.footerIcon = 'fas fa-exclamation-triangle'
        } else if (response.data == 'PENDING_VALIDATION') {
          this.successCard.footerText = 'Status: Conta em Análise'
          this.successCard.footerIcon = 'fas fa-exclamation-triangle'
        }
      },
      nickname() {
        if (isNullOrEmpty(this.name)) {
          return ''
        }
        let split = this.name.split(' ')
        if (split.length == 1) {
          return split[0]
        }
        return split[0] + ' ' + split[split.length - 1]
      }
    }
  };
</script>
<style></style>
