export function getTransactionName(type, subtype) {
  switch (type) {
    case 'TED':
      return "Envio TED"
    case 'TEV':
      return "Transf. Contas"
    case 'BOLETO':
      return "Depós. Boleto"
    case 'FEE':
      return "Taxa" + " " + getTransactionName(subtype, null)
    case 'SUBSCRIPTION':
      return "Mensalidade"
    case 'CHARGEBACK':
      return "Estorno" + " " + getTransactionName(subtype, null)
    case 'BONUS':
      return "Bônus por Indicação"
    case 'TED_DEPOSIT':
      return "Depós. TED"
    case 'BANK_SLIP_PAYMENT':
      return "Pag. boleto"
    case 'PIX_PAYMENT':
      return "Envio PIX"
    case 'PIX_DEPOSIT':
      return "Receb. PIX"
    case 'PIX_DEVOLUTION':
      return "Devolução PIX"
    case 'PIX_POOL_UNASSIGN':
      return "Desatribuição PIX"
  }
  return "Outros"
}

export function getTransactionLogo(type) {
  switch (type) {
    case 'TED':
      return "fa fa-arrow-up text-red"
    case 'TEV':
      return "fas fa-exchange-alt text-red"
    case 'BOLETO':
      return "fas fa-file-download text-green"
    case 'BANK_SLIP_PAYMENT':
      return 'fas fa-file-upload text-red'
    case 'FEE':
      return "fas fa-receipt text-yellow"
    case 'SUBSCRIPTION':
      return "far fa-calendar-check text-red"
    case 'CHARGEBACK':
      return "fas fa-backward text-blue"
    case 'BONUS':
      return "fas fa-user-friends text-green"
    case 'TED_DEPOSIT':
      return "fa fa-arrow-down text-green"
    case 'PIX_DEPOSIT':
      return "fas fa-angle-double-down text-green"
    case 'PIX_PAYMENT':
      return "fas fa-angle-double-up text-red"
    case 'PIX_DEVOLUTION':
      return "fas fa-backward text-blue"
    case 'PIX_POOL_UNASSIGN':
      return "fas fa-eraser text-red"
  }
  return "fas fa-star-of-life text-info"
}


export function getTransactionIcon(type) {
  switch (type) {
    case 'TED':
      return "ni ni-fat-delete text-red"
    case 'TEV':
      return "ni ni-fat-delete text-red"
    case 'BOLETO':
      return "ni ni-fat-add text-green"
    case 'FEE':
      return "ni ni-fat-delete text-red"
    case 'SUBSCRIPTION':
      return "ni ni-fat-delete text-red"
    case 'CHARGEBACK':
      return "ni ni-fat-add text-green"
    case 'BONUS':
      return "ni ni-fat-add text-green"
    case 'TED_DEPOSIT':
      return "ni ni-fat-add text-green"
    case 'BANK_SLIP_PAYMENT':
      return "ni ni-fat-delete text-red"
    case 'PIX_DEVOLUTION':
      return "ni ni-fat-add text-green"
    case 'PIX_PAYMENT':
      return "ni ni-fat-delete text-red"
    case 'PIX_POOL_UNASSIGN':
      return "ni ni-fat-delete text-red"
    case 'PIX_DEPOSIT':
      return "ni ni-fat-add text-green"
  }
  return "fa ni-fat-delete text-red"
}
